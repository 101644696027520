import React, { useState, createContext } from 'react'

export const TransitionContext = createContext()

export const TransitionProvider = ({ children }) => {
  const [transitionActive, setTransitionActive] = useState(false)

  return (
    <TransitionContext.Provider value={[transitionActive, setTransitionActive]}>
      {children}
    </TransitionContext.Provider>
  )
}
